@font-face {
    font-family: 'icomoon';
    src:  url('../fonts/icomoon.eot?893dun');
    src:  url('../fonts/icomoon.eot?893dun#iefix') format('embedded-opentype'),
      url('../fonts/icomoon.ttf?893dun') format('truetype'),
      url('../fonts/icomoon.woff?893dun') format('woff'),
      url('../fonts/icomoon.svg?893dun#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: "SansSerifBold";
    src: url("../fonts/911Fonts.com_SansSerifBold__-_911fonts.com_fonts_7MJK.ttf") format('truetype');
  }
  @font-face {
    font-family: "Miratrix";
    src: url("../fonts/ofont.ru_Miratrix.ttf") format('truetype');
  }
  [class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .icon-envelope-o:before {
    content: "\f003";
  }
  .icon-phone:before {
    content: "\f095";
  }
  .icon-facebook:before {
    content: "\f09a";
  }
  .icon-facebook-f:before {
    content: "\f09a";
  }
  .icon-angle-double-left:before {
    content: "\f100";
  }
  .icon-angle-double-right:before {
    content: "\f101";
  }
  .icon-angle-left:before {
    content: "\f104";
  }
  .icon-angle-right:before {
    content: "\f105";
  }
  .icon-mobile:before {
    content: "\f10b";
  }
  .icon-mobile-phone:before {
    content: "\f10b";
  }
  .icon-quote-left:before {
    content: "\f10d";
  }
  .icon-instagram:before {
    content: "\f16d";
  }
  .icon-telegram:before {
    content: "\f2c6";
  }
  .icon-check:before {
    content: "\f00c";
  }
  .icon-angle-down:before {
    content: "\f107";
  }
  .icon-angle-up:before {
    content: "\f106";
  }
  .icon-bars:before {
    content: "\f0c9";
  }