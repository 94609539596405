.App {
    text-align: center;

}
.header__content {
    width: 100%;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(240, 240, 240, 0.9);
    margin: 0 auto;
    z-index: 99;
}
.loader {
    border: 6px solid #f3f3f3; /* Light grey */
    border-top: 6px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 45px;
    height: 45px;
    margin-right: 20px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
.header__top {
    width: 100%;
    height: 30px;
    display: flex;
    justify-content: center;
    background: #15337eed;
    align-items: center;

}

.header {

    position: fixed;
    z-index: 99999;
    width: 100%;

}

.slider-main {
    position: relative;
    top: 0;
}

.slick-prev {
    left: -20px !important;
}

.next-slick-arrow,
.prev-slick-arrow {
    color: #1da1f2;
    font-size: 48px;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.admin_create_news-ta {
    display: flex;
    flex-direction: column;

}

.Sidebar {
    position: static;
    height: 100vh;
    background: #757575;
    width: 12vw;
}

.SidebarList {
    height: auto;
    padding: 0;
    width: 100%;
}
.admin_news{
    margin: 0px 20px 0px 20px;
    width: 85vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.top_panel{
    width: 100%;
    height: 20vh;
    padding: 10px 30px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

::-webkit-input-placeholder{
    color: #969696;
}
.news_panel {
    height: 75vh;
    border-radius: 40px;
    padding: 30px;
    position: static;
    width: 100%;
    background: #444444;
}
.news_panel > div{
    overflow-y:scroll;
    max-height: 100%;
}
.icon_td{
    position: relative;
    min-width: 95px;
}
.news_table {
    width: 97%;
    position:relative;
    margin-top: 20px;
    border: 1px solid #dddddd;
    border-collapse: collapse;
}

.news_table th {
    font-weight: bold;
    padding: 5px;
    background: #efefef;
    border: 1px solid #dddddd;
}

.news_table td {
    border: 1px solid #dddddd;
    padding: 5px;
}

.active_sidebar li {
    background: #888888;
}

.SidebarList .row .Activity {
    background: #888888;
}

.SidebarList .row {
    display: flex;
    flex-direction: row;
    color: white;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
    list-style-type: none;
    margin: 0%;

}
.admin_panel {
    display: flex;
    flex-direction: row;
}

.SidebarList .row div {
    display: flex;
    flex-direction: row;
    color: white;
    justify-content: center;
    align-items: center;
    flex: 70%;
}

.SidebarList .row:hover {
    cursor: pointer;
    background: #888888;
}

.text_title_send {
    border: 3px solid #5a5959;
    border-radius: 10px 10px 10px 10px;
    margin: 2px;
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
}

.admin_create_news-image {
    width: 150px;
    min-height: 100px;
    /*margin-left: 5%;*/
    border: 3px solid #5a5959;
    border-radius: 7%;
    resize: none;
    padding: 5px 5px 5px 5px;
    text-align: justify;
    /*float: right;*/
}
.admin_create_news > div {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-evenly;
    height: 100%;
}
.admin_create_news {
    border: #4e4e4e 3px;
    margin: 10px 10px 10px 10px;
    display: flex;
    flex-direction: column;
}

.testimonials__slider {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.testimonials__name {
    display: flex;
    position: relative;
    justify-content: right;
    margin-left: 140px;
    padding: 20px 0;
    font-weight: 600;
    font-size: 30px;
    color: #15337eed;
}

.testimonials__text {
    display: flex;
    text-align: justify;
    margin: 20px auto;
    width: 500px;
    min-height: 200px;
    height: auto;
    background-color: transparent;
    font-style: italic;
    font-weight: 100;
    position: static;
    border-radius: 22px;
    border: 4px solid #15337eed;
    font-size: 20px;
    padding: 25px;
    color: #15337eed;
}

.testimonials__text__main {
    width: 442px;
    -webkit-line-clamp: 6;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.testimonials__items img {
    position: absolute;
    display: flex;
    width: 105px;
    justify-content: right;
    margin: 10px 30px;
    border-radius: 100%;
    border: 3px solid rgb(230, 226, 226);
    box-shadow: 0px 7px 24.3px 2.7px rgb(11 38 124 / 14%);

}

.footer__icons {
    display: flex;
    opacity: 0.8;
    align-self: center;
}

.footer__icons a {
    position: relative;
    padding: 0 7px 0 0;

}

.copyright__block {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.copyright__text {
    display: flex;
    justify-content: center;
    align-items: end;
}

.testimonials {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5vh;
    background-color: white;
}

.icon {
    width: 30px;
    height: 30px;

}

.rofl {
    padding: 5px 0px 5px 0px;
    height: 90px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.testimonials__items {
    display: flex;
    position: static;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: transparent;
    color: #15337eed;;

    margin: 0 auto

    /* border-radius: 25px; */;
}

.combo-img {
    position: absolute;
    width: 500px;
    height: auto;
}

.testimonials__position {
    position: relative;
    margin-left: 140px;
    display: flex;
    justify-content: right;
    align-items: center;
    font-size: 20px;
    color: #15337eed;
    font-weight: 300;
    line-height: 150%;
}


.textNews{
    -ms-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
    white-space: pre-wrap;
    text-indent: 20px;
}
.location{
    display: flex;
    align-items: center;
}
.textLocation{
    margin-top: 10px;
    width: 100%;
    padding-left: 1em;
    line-height: 0.5;
    text-align: justify;
}
.mapLocation{
    margin: 1em 1em;
}

.swiper {
    width: 100%;
    height: 100%;
}
.popup-menu{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    border-radius: 5px;
    width: 120px;
    height: 50px;
    border: 1px solid black;
    background: white;
    overflow-y:hidden !important;
    position: absolute;
    bottom: 65%;
    right: 10%;
}

.delete_block{
    display: flex;
    flex-direction: column;
    align-items:self-end;
}