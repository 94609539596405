/*Обнуление*/
* {
    padding: 0;
    margin: 0;
    border: 0;
}

*, *:before, *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

:focus, :active {
    outline: none;
}

a:focus, a:active {
    outline: none;
}

nav, footer, header, aside, news {
    display: block;
}

html, body {
    margin: 0;
    padding: 0;
    height: 100%;
    line-height: 22px;
    font-size: 18px;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 400;
    color: rgba(71, 71, 71, 0.9);
    -ms-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;

}

input, button, textarea {
    font-family: inherit;
}

input::-ms-clear {
    display: none;
}

button {
    cursor: pointer;
}

button::-moz-focus-inner {
    padding: 0;
    border: 0;
}

a, a:visited {
    text-decoration: none;
}

a:hover {
    text-decoration: none;
}

ul li {
    list-style: none;
}

/*--------------------*/
body {
    background-color: rgb(240, 240, 240, 0.9);


}

.wrapper {
    min-height: 100%;
    display: flex;
    flex-direction: column;
}

main {
    flex: 1 1 auto;
}

.container {
    width: 1170px;
    padding: 0px 15px 0px 15px;
    margin-left: 0%;
    margin-right: 0%;
}

.header__row {
    font-family: Roboto;
    width: 100%;

    display: inline-flex;
    justify-content: space-between;
    flex-wrap: wrap;
    font-size: 14px;
    align-items: center;
    font-weight: 400;
    color: #f0f0f0;
    margin: 7px auto;
}

.header__row a {
    margin: 0 20px 0 0;
    color: #f0f0f0

}

.header__btn-menu {
    display: none;
}

.footer__contacts {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 100%;
    margin-bottom: 10px;
}

.header__contacts {
    display: flex;
    width: 50%;
    justify-content: space-between;
    flex-wrap: wrap;
}

.header__emaiL::before {
    content: "\f003";
    font-family: 'icomoon';
    position: relative;
    margin-right: 8px;
    align-items: center;

}

.map-marker::before {
    content: "\f041";
    font-family: FontAwesome;
    position: relative;
    margin-right: 8px;
    align-items: center;
}

.header__phone::before {
    content: "\f095";
    font-family: 'icomoon';
    position: relative;
    margin-right: 8px;
    align-items: center;
}

.header__mobile::before {
    content: "\f10b";
    font-family: 'icomoon';
    position: relative;
    margin-right: 8px;
    align-items: center;
}

.lang-menu {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: right;
}

.selected-lang {
    cursor: pointer;
    background: transparent;
    display: flex;
    width: 90px;
}

.lang-menu ul {
    margin: 0;
    padding: 0;
    background-color: white;
    border: 1px solid #f8f8f8;
    box-shadow: 0px 1px 10px rgba(248, 248, 248, 0.2);
    border-radius: 5px;
    display: none;
    position: absolute;
    top: 25px;
    right: 13px;
    z-index: 99999;
}

.lang-menu:hover ul {
    display: block;

}

.lang-menu ul li a {
    width: 125px;
    display: block;
    padding: 5px 10px;
    color: black;
}

.lang-menu ul li a:hover {
    background-color: rgba(21, 51, 126, 0.2);
}


.header__inner {
    display: flex;
    justify-content: space-around;
    align-items: center;

}

.header__content-logo img {
    justify-content: center;
    align-items: center;
    margin: 10px auto;
}

/* меню-начало */
nav {
    background: transparent;

}

nav ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

nav ul:after {
    content: "";
    display: table;
    clear: both;
}

nav a {
    text-decoration: none;
    display: block;
    transition: .3s linear;
}

.topmenu {
    display: flex;
    justify-content: space-between;
}

.topmenu > li {
    height: 60px;
    float: left;
    position: relative;
}

.topmenu > li > div {
    padding: 20px 25px;
    font-size: 18px;
    text-transform: uppercase;
    color: black;
    font-weight: 600;
    white-space: nowrap;
}
.topmenu > li > div:hover,
.topmenu > li > a:hover{
    color:white;
}
.topmenu > li > a {
    padding: 20px 25px;
    font-size: 18px;
    text-transform: uppercase;
    color: black;
    font-weight: 600;
    white-space: nowrap;
}

.topmenu li :hover {
    background-color: rgba(21, 51, 126,0.9);
}

.topmenu li:active {
    border-bottom: 2px solid #15337eed;
}

.submenu :hover {
    color: white;
    background-color: rgba(21, 51, 126);
}


.topmenu .fa,
.submenu .fa {
    margin-left: 5px;
    margin-top: 5px;
    color: inherit;
}

.submenu {
    position: absolute;
    z-index: 88888;
    min-width: 120%;
    visibility: hidden;
    opacity: 0;
    transform-origin: 0% 0%;
    transition: .3s linear;
}

.submenu li {
    position: relative;
}

.submenu li a {
    color: #282828;
    padding: 10px 20px;
    background-color: white;
    font-weight: 300;
    max-width: 100%;
}

.submenu .submenu {
    position: absolute;
    left: 100%;
    top: -1%;
    /* top: ; */
    transition: .3s linear;
}

nav li:hover > .submenu {
    visibility: visible;
    opacity: 1;
}

/* меню-конец */


/* слайдер начало */

.slider {
    max-width: 100%;
    height: 500px;
}

/* дополнительные стили для этого примера */
.slider__items {
    counter-reset: slide;
}

.slider__item {
    float: left;
    position: relative;
    width: 100%;
    height: 500px;
    width: 100%;
    border: 1px solid #ccc;
}

.slider__item::after {
    position: absolute;
    top: 10px;
    right: 20px;
    color: #fff;

    font-style: italic;
    font-size: 32px;
    font-weight: bold;
}

.img-fluid {
    position: static;
    display: inline-block;
    height: auto;
    -webkit-filter: brightness(50%);
    width: 100vw;

}

.slider__content {
    display: flex;
    align-items: center;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 500px;
    top: 20%;
    position: absolute;
    text-align: center;
    color: white;
}


.slider__title {
    text-align: left;
    width: 100%;
    font-size: 44px;
    line-height: 40px;
    font-weight: 600;
    margin: 55px 0;
}

.slider__text {
    width: 100%;
    white-space: pre-wrap;
    text-align: left;
    color: white;
    font-size: 24px;
    line-height: 1;
    font-weight: 100;
}

.slider__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 130px;
    text-align: center;
    border: 2px solid white;
    border-radius: 10px;
    margin-top: 60px;
    height: 40px;
}

.slider__btn a:hover {
    color: black;
}

.slider__btn:hover {
    background-color: white;
}

.slider__btn a {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    color: white;

}

.slider__btn a :hover {
    background-color: white;
    color: #15337eed;
    margin: 10px;
}

.about__content {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.about__title, .about__title-sphera,
.news__title, .capabilities__title,
.clients__title, .partners__title,
.testimonials__title, .services__title {
    display: flex;
    text-align: center;
    margin-top: 2vw;
    justify-content: left;
    font-size: 36px;
    width: 100%;
    line-height: 50px;
    color: #15337eed;
    font-weight: 700;
}

.about__text, .services__text {
    display: flex;
    justify-content: space-between;
    font-size: 22px;
    line-height: 1.2;
    font-weight: 400;
    text-align: justify;
    margin: 15px auto;
}

.about__link {
    display: flex;
    justify-content: right;
    color: #15337eed;
    line-height: 22px;

    font-weight: 600;
}

.about__items {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.about__item {
    background-color: white;
    height: 600px;
    margin: 30px 0;
    color: #01447e;
    width: 31%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-radius: 15px;
    padding: 20px;
    box-shadow: 0px 7px 24.3px 2.7px rgba(11, 38, 124, 0.14);
}

.about-img img {
    width: 95px;
    height: 95px;
    /* -webkit-filter: brightness(50%); */
}

.about__item-title {
    display: flex;
    font-size: 36px;
    line-height: 45px;
    font-weight: 700;
}

.about__item-text {
    display: flex;
    color: #5a5959;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    text-align: justify;
    white-space: normal;
}

.about__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 125px;
    text-align: center;
    border: 2px solid #15337eed;
    border-radius: 10px;
    margin-top: 15px;
    height: 40px;
}

.about__item__btn, .news__item__btn {
    border: 2px solid #15337eed;
    border-radius: 10px;
    width: 185px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

}

.about__item__btn a, .news__item__btn a {
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    width: 100%;
    font-size: 18px;
    color: #15337eed;
    font-weight: 600;
    height: 40px;
    border-radius: 10px;
}

.news__item__btn a:hover, .news__item__btn:hover {
    background-color: #15337eed;
    color: white;
}

.about__item__btn:hover {
    background-color: #15337eed;
}

.about__item__btn a:hover {
    color: white;
}

.news {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.news__items {
    display: flex;
    justify-content: left;
    align-items: flex-start;
    width: 100%;
}

.news__item {
    display: flex;
    flex-direction: column;
    /*justify-content: center;*/
    align-items: center;
    width: 150px;
    height: 400px;
    margin: 25px 0;
    /*background-color: white;*/
    /*box-shadow: 0px 1px 7.3px 1.7px rgba(11, 38, 124, 0.14);*/

}

.bullet_style{
    height: 13px !important;
    width: 13px !important;
}
.news__item:hover {
    cursor: pointer;;
    transform: scale(1.05);
    background-color: rgb(240, 240, 240, 0.7);
}

.news__item img {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    width: 100%;
    margin: 10px auto;
}

.news__item__title {
    display: flex;
    text-align: left;
    width: 90%;
    font-size: 22px;
    font-weight: 700;
    color: #15337eed;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;

}

.news__item__text {
    display: flex;
    text-align: justify;
    width: 100%;
    -webkit-line-clamp: 9;
    line-height: 1.2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    padding: 0 30px 0 20px;
    font-size: 16px;

}

.sliick.slick-dots ul {
    color: royalblue;
}

.news__item__btn {
    margin: 30px auto;
}

.capabilities {
    display: flex;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    margin: 25px auto;
}

.capabilities__items {
    display: flex;
}

.capabilities__item {
    display: flex;
    flex-direction: column;
    background-color: #15337eed;
    width: 19%;
    margin: 20px auto;
}

.capabilities__item:hover {
    transform: scale(1.05);
    box-shadow: 0px 7px 24.3px 2.7px rgba(11, 38, 124, 0.14);

}

.capabilities__item__title {
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-size: 18px;
    line-height: 25px;
    color: white;
    text-align: center;
    height: 60px;
    padding: 10px;
}

.capabilities__img {
    width: 100%;
    transform: scale(.95);
    border-radius: 5px;
    -webkit-filter: brightness(80%);
}

.capabilities__item__text {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: justify;
    font-size: 14px;
    color: white;
    padding: 10px;

}

.clients, .partners {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
}

.slider__btn a:hover {
    color: black;
}

.slider__btn:hover {
    background-color: white;
}

.clients__items, .partners__items {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.slide_partner div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.slide_partner img {
    height: 70px;
}

/*.slide_partner div:last-child {*/
/*    font-size: 14px;*/
/*    text-align: justify;*/
/*    padding-right: 8px;*/

/*}*/

.slide_partner {
    display: grid !important;
    grid-template-columns: 1fr 2fr 1fr 3fr;
    background: white;
    height: 80px !important;
}

.slide_news #number,
.slide_news #date {
    display: flex;
    justify-content: center;
    align-items: center;
}

.slide_news > div {
    display: flex;
    align-items: center;
    text-align: justify;
    padding: 5px;
}
.slide_news > div > span {
    word-break: break-all;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow:clip;
    overflow: hidden;
}

.slide_news {
    color: #15337e;
    display: grid !important;
    grid-template-columns: 1fr 9fr 3fr 2fr;
    background: white;


    height: 60px !important;
}
.slide_news div:last-child{
    word-break: normal;
}
.clients__item, .partners__item {
    display: flex;
    justify-content: center;
    align-items: center;
    /* -webkit-filter: brightness(90%); */
    /* border:2px solid #15337eed; */
}

.clients__item img, .partners__item img {
    width: 165px;
    margin: 20px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    box-shadow: 0px 7px 24.3px 2.7px rgba(11, 38, 124, 0.14);
}

.clients__item img:hover, .partners__item img:hover {
    transform: scale(1.05);
}

.slick-prev:before, .slick-next:before {
    color: #15337eed !important;
}

/* .about__btn{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 125px;
  text-align: center;
  border: 2px solid #15337eed;
  border-radius: 10px;
  margin-top: 15px;
  height: 40px;
  } */

.capabilities__item__btn {
    border: 2px solid #15337eed;
    border-radius: 10px;
    width: 400px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px auto;

}

.capabilities__item__btn a {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 400px;
    font-size: 18px;
    color: #15337eed;
    font-weight: 600;
    height: 40px;
    border-radius: 10px;
}

.capabilities__item__btn a:hover {
    background-color: #15337eed;
    color: white;
}


.testimonials__text .quote {
    font-family: 'Secular One', sans-serif;;
    content: " ";
    font-size: 40px;
    color: #15337eed;
}


#slick-slide30 {
    height: 265px;
}

.footer__row {
    display: flex;
    justify-content: center;
    align-items: center;

}

.footer__content {
    margin-top: 15px;
    width: 100%;
    display: flex;
    justify-content: space-between;

}

.footer__logo__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 70%;
}

.footer__logo_slogan {
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.footer__slogan {
    line-height: 15px;
    font-size: 25px;
    margin: auto 16px;
}

.footer__slogan .highlighting {
    display: flex;;
    font-size: 42px;
    font-weight: 700;
}

.footer__text {
    width: 70%;
    margin: 25px 0;
    text-align: justify;
}

.footer__short-menu, .footer__sitemap {
    display: flex;
    column-gap: 30px;
    row-gap: 5px;
    justify-self: self-start;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
}

.footer__short-menu a, .footer__sitemap a {
    text-align: center;
    font-size: 1rem;
    color: white;
}

.footer__short-menu-title, .footer__sitemap-title {
    font-size: 24px;
    font-weight: 600;
    color: #15337eed;
    margin: 20px;
}

.footer__copyright {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: #0b1c3f;
    margin-top: 20px;
    color: #f0f0f0;
}

footer {
}

.banner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    position: relative;
}

.banner img {
    width: 100%;
    height: 500px;
    -webkit-filter: brightness(50%);

}

.banner__title {
    width: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
    position: absolute;
    top: 50%;
    font-size: 56px;
    line-height: 62px;
    color: white;
    text-transform: uppercase;
    font-weight: 600;

}

.submenues {
    margin: 0 auto;
    padding: 25px 0;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #15337eed;

}

.submenues__items {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.submenues__item {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 6px solid white;
    border-radius: 10px;
    width: 200px;
    text-align: center;
}

.submenues__item a {
    padding: 25px 25px;
    color: white;
    font-size: 22px;
    font-weight: 700;
    text-transform: uppercase;
    border-radius: 10px;
    width: 200px;
    text-align: center;
}

.submenues__item a:hover, .submenues__item:hover {
    background-color: white;
    color: #15337eed;

}

.services, aboutus {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: start;
    background-color: white;
}

.plan, .design, .delivery, .manage, .decomission, .procurement {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}
.Login {
    padding: 60px 0;
}

.Login form {
    margin: 0 auto;
    max-width: 320px;
}
.plan__contents, .design__contents, .delivery__contents, .manage__contents, .decomissoin__contents {
    /* border-bottom: 5px groove #15337eed ; */
}

.plan__title, .design__title, .delivery__title, .manage__title, .decomission__title {
    display: flex;
    justify-content: left;
    align-items: center;
    padding: 50px 20px;
    text-align: center;
}

.plan__title p, .design__title p, .delivery__title p, .manage__title p, .decomission__title p {
    margin: 20px 20px;
    /* font-family: 'Merriweather', serif; */
    position: relative;
    color: black;
    font-size: 36px;
    font-weight: 500;
    padding: 8px 20px 7px 20px;
    border-top: 4px solid;
    border-left: 4px solid;
    display: inline-block;
    border-color: #15337eed;
    margin: 0;
    line-height: 1;
}

.plan__title p::before, .design__title p::before, .delivery__title p::before, .manage__title p::before, .decomission__title p::before {
    content: "";
    position: absolute;
    width: 25px;
    height: 25px;
    top: -25px;
    left: -25px;
    border: 4px solid #15337eed;
    box-sizing: border-box;
}

.plan__content, .design__content, .delivery__content, .manage__content, .decomission__content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

}

.plan__content-text, .design__content-text,
.delivery__content-text, .manage__content-text, .decomission__content-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    font-size: 22px;
    text-align: justify;
    margin: 0 20px;
    text-indent: 5%;
    line-height: 26px;
}

.plan__content-text ul, .design__content-text ul, .delivery__content-text ul, .manage__content-text ul, .decomission__content-text ul {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 30px auto;
    text-indent: 0;
    flex-wrap: wrap;
}

.headtext {
    width: 100%;
    font-family: SansSerifBold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-indent: 0;
    flex-wrap: wrap;
    font-weight: 600;
    line-height: 1.3;
    color: #15337eed;
    word-wrap: normal;
}

.plan__content-text li, .design__content-text li, .delivery__content-text li, .manage__content-text li, .decomission__content-text li {
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    color: #15337eed;
    word-wrap: normal;
}

.design__content-text ul {
    width: 100%;
    display: flex;
    justify-content: left;
    flex-direction: column;
    align-items: flex-start;
    margin: 30px auto;
    text-indent: 0;

}

.plan__content-text li::before, .design__content-text li::before,
.delivery__content-text li::before, .manage__content-text li::before, .decomission__content-text li::before {
    font-family: 'icomoon';
    content: "\f105";
    font-size: 20px;
    color: #15337eed;
    font-weight: 600;
    padding-right: 5px;
    top: 50%;
    transform: translatey(-50%);
    line-height: 25px;
}

.work__title {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 28px;
    font-weight: 600;
    margin: 20px auto;
    text-transform: uppercase;
}

.works__slider {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.works__slider_text {
    text-align: center;
    position: relative;
    top: 5%;
    font-size: 12px;
    color: white;
    margin: auto 15px;
}

#slick-slide00, #slick-slide11, #slick-slide22 {
    height: 100px;
}

.works__slider img {
    position: absolute;
    width: 220px;
    height: 100px;
    margin: 0 auto;
    -webkit-filter: brightness(50%);

}

.project_slide {
    height: 350px;
    width: 280px;
    background: #15337e;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
}

.project_slide span {
    text-align: center;
    color: white;
    padding: 0 5px;
}

.project_slide img {
    width: 190px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.text_area_news{
    padding: 10px 10px 10px 10px;
    border-radius: 10px 10px 10px 10px;
    resize: none;
    border: 3px solid #5a5959;
    height: 200px;
    text-align: justify;
    width: 100%;
    word-break: break-all;
}
.mapLocation {
    margin: 1em 1em;
}

.slide_partner_text {
    font-size: 14px;
    text-align: justify;
    padding-right: 8px;
}

@media screen and (min-width: 320px) and (max-width: 1080px) {

    @media screen and (max-width: 1080px) {
        .container {
            width: 800px;
        }

        .slide_partner div {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 13px;
        }

        .slide_partner img {
            height: 65px;
        }

        .slide_partner div:last-child {
            font-size: 13px;
            text-align: justify;
            padding-right: 8px;

        }

        .slide_partner {
            display: grid !important;
            grid-template-columns: 1fr 2fr 1fr 3fr;
            background: white;
            height: 80px !important;
        }

        .slider__item {
            height: 270px;
        }

        .slide_news {
            height: 72px !important;
        }

        .slide_news > div {
            font-size: 16px;
        }

        .slide_button div {
            font-size: 14px;
            padding: 0 5px;
        }

        .news__item {
            width: 100%;
            height: 400px;
        }

        .capabilities__item__title {
            font-size: 11px;
        }


        .capabilities__item__text {
            font-size: 14px;
            color: white;

        }


        .testimonials__name {
            display: flex;
            position: relative;
            margin-left: 160px;
            justify-content: left;
            padding: 30px 0 0 0;
            font-weight: 600;
            font-size: 28px;
            color: #15337eed;
        }

        .testimonials__position {
            position: relative;
            display: flex;
            margin-left: 160px;

            justify-content: left;
            align-items: center;
            font-size: 22px;
            color: #15337eed;
            font-weight: 300;
        }

        .testimonials__text__main {
            width: 600px;
            font-size: 20px;
            -webkit-line-clamp: 6;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }
        .project_slide{
            width: 320px;
        }
        .about__title, .about__title-sphera,
        .news__title, .capabilities__title,
        .clients__title, .partners__title,
        .testimonials__title, .services__title {
            font-size: 20px;
            text-transform: uppercase;
            line-height: 1.5;
        }

        .about__item-title {
            display: flex;
            font-size: 28px;
            line-height: 45px;
            font-weight: 700;
        }

        .about__item-text {
            display: flex;
            color: #5a5959;
            font-size: 14px;
            font-weight: 400;
            line-height: 1.2;
            text-align: justify;
            white-space: normal;
        }

        .about__text, .services__text {
            font-size: 16px;
            margin: 0;
        }

        .about__link {
            line-height: 1;
            font-size: 13px;
            font-weight: 600;
        }

        .topmenu > li > div {
            padding: 20px 15px;
            font-size: 14px;
            text-transform: uppercase;
            color: black;
            font-weight: 600;
            white-space: nowrap;
        }

        .topmenu > li > a {
            padding: 20px 15px;
            font-size: 14px;
            text-transform: uppercase;
            color: black;
            font-weight: 600;
            white-space: nowrap;
        }

        .slider__item {
            height: 250px;
        }

        .slider__text {
            line-height: 1.5;
            font-size: 18px;
        }

        .slider__title {
            margin: 10px 0;
            line-height: 1;
            font-size: 18px;
        }

        .slider__btn a {
            font-size: 70%;
        }

        .slider__btn {
            border: 1px solid white;
            border-radius: 5px;
            width: 100px;
            margin-top: 10px;
            height: 23px;
        }
    }

    @media screen and (max-width: 768px) {
        .testimonials__slider {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
        }

        .slide_news > div, .slide_news input {
            font-size: 12px;
        }

        .project_slide {
            height: 200px !important;
            display: grid;
            border-radius: 0;
            width: 100%;
            grid-template-columns: 1fr 2fr;
            grid-template-rows: 1fr 1fr;
        }

        .project_slide span {
            text-align: center;
            color: white;
            font-size: 22px;
        }

        .project_slide div {
            grid-column-start: 2;
        }

        .project_slide img {
            width: 180px;
            height: 180px;
            margin-left: 10px;
            grid-row-start: 1;
            grid-row-end: 3;
        }

        .testimonials__name {
            display: flex;
            position: relative;
            justify-content: left;
            margin-left: 110px;
            padding: 50px 0 0 0;
            font-weight: 600;
            font-size: 20px;
            color: #15337eed;
        }

        .testimonials__position {
            position: relative;
            margin-left: 110px;
            display: flex;
            justify-content: left;
            align-items: center;
            font-size: 16px;
            color: #15337eed;
            font-weight: 300;
        }

        .testimonials__text {
            display: flex;
            text-align: justify;
            margin: 20px auto;
            width: 100%;
            min-height: 200px;
            height: auto;
            background-color: transparent;
            font-style: italic;
            font-weight: 100;
            position: static;
            border-radius: 22px;
            border: 4px solid #15337eed;
            font-size: 13px;
            padding: 25px;
            color: #15337eed;
        }

        .testimonials__text__main {
            width: 240px;
            font-size: 15px;
            -webkit-line-clamp: 6;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }

        .testimonials__items img {
            position: absolute;
            display: flex;
            width: 95px;
            justify-content: right;
            background: white;
            margin: 50px 0px;
            border-radius: 100%;
            border: 3px solid rgb(230, 226, 226);
            box-shadow: 0px 7px 24.3px 2.7px rgb(11 38 124 / 14%);

        }

        .capabilities__items {
            display: flex;
            flex-wrap: wrap;
        }

        .capabilities__item {
            display: flex;
            flex-direction: column;
            background: #15337e;
            width: 100%;
            margin: 5px auto;
        }

        .capabilities__item:hover {
            transform: none;
            box-shadow: none;

        }

        .capabilities__item__title {
            font-size: 14px;
            width: 100%;
            font-weight: 600;
            height: 40px;
            padding: 10px;
        }

        .capabilities__img {
            display: none;
        }

        .capabilities__item__text {
            font-size: 14px;
            color: white;

        }

        .capabilities__item__btn {
            display: none;
        }

        .about__items {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .about__item {
            background-color: #15337e;
            height: auto;
            margin: 5px 0;
            width: inherit;
            color: white;
            display: grid;
            grid-template-columns: 1fr 2fr;
            grid-template-row: 1fr 1fr;
            border-radius: 0;
            padding: 10px;
            box-shadow: 0 0 0 0;
        }

        .about__item__btn {
            border-radius: 5px;
            width: auto;
            height: 25px;
            grid-column-start: 1;
            grid-row-start: 2;
            justify-content: center;
            align-items: center;
            border: 1px solid white;
        }

        .about__item__btn a, .news__item__btn a {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            color:white;
            font-size: 11px;
            font-weight: 600;
            height: 40px;
            border-radius: 10px;
        }

        .about__item__btn a:hover {
            color: white;
        }

        .about__item__btn:hover {
            background-color: #15337eed;
        }

        .about__item__btn a:active {
            color: #15337eed;

        }

        .about__item__btn:active {
            background-color: white;
        }

        .about-img img {
            display: none;
        }

        .about__item-title {
            grid-column-start: 1;
            grid-row-start: 1;
            display: flex;
            justify-content: center;
            font-size: 16px;
            line-height: 1;
            font-weight: 700;
        }

        .about__item-text {
            margin-left: 5px;
            padding-left: 5px;
            height: 100%;
            align-items: center;
            border-left: 1px solid white;
            grid-row-start: 1;
            grid-row-end: 3;
            grid-column-start: 2;
            color: white;
            font-size: 11px;
            font-weight: 400;
            line-height: 1.2;
            text-align: justify;
            white-space: normal;
        }

        .slide_partner {
            display: grid !important;
            padding: 5px;            grid-template-rows: repeat(3, 1fr);
            grid-template-columns: 2fr 5fr;
            background: white;
            font-size: 14px;
            height: 110px !important;
        }

        .slide_partner img {
            height: 65px;
        }

        .slide_partner div {
            font-size: 9px;
        }

        .slide_partner div:last-child {
            grid-column-start: 2;
            grid-row-start: 1;
            grid-row-end: 4;
            font-size: 100%;
        }

        .projects__item__btn {
            width: 100px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: auto;
        }


    }
    @media screen and (max-width: 600px) {
        .container {
            width: 500px;
        }
        .footer__sitemap{
            column-gap: 10px;
        }
    }
    @media screen and (max-width: 480px) {

        .slider__content {
            top: 5%
        }

        .project_slide {
            height: 200px !important;
            display: grid;
            border-radius: 0;
            width: 100%;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 1fr 1fr;
        }

        .project_slide span {
            text-align: center;
            color: white;
            font-size: 60%;
        }

        .project_slide div {
            grid-column-start: 2;
        }

        .project_slide img {
            width: 150px;
            height: 150px;
            margin-left: 10px;
            grid-row-start: 1;
            grid-row-end: 3;
        }

        .news__item {
            display: grid;
            grid-template-rows: repeat(5, 1fr);
            grid-template-columns: 1fr 2fr;
            width: 100%;
            height: 120px;
            background-color: white;
            padding: 0;
        }

        .news__item:hover {
            cursor: none;
            transform: none;
        }

        .news__item img {
            grid-row-start: 1;
            grid-row-end: 5;
            width: 100px;
            height: 80px;
            margin: 10px;
        }

        .news__item__title {
            text-align: left;
            width: 90%;
            font-size: 9px;
            font-weight: 700;
            color: black;
            text-overflow: ellipsis;
            -webkit-line-clamp: 1;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;

        }

        .news__item__text {
            display: flex;
            align-self: flex-start;
            grid-row-start: 2;
            grid-row-end: 5;
            text-align: justify;
            width: 100%;
            line-height: 0.8;
            padding: 0 20px 0 0;
            -webkit-line-clamp: 6;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }
        .news__item__text span{
            font-size: 8px !important;

        }
        .news__title {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }

        .news__title a {
            color: #15337e;
            display: flex;
            font-size: 14px;
            text-transform: none;
            align-self: self-end;
        }

        @media screen and (min-width: 320px) and (max-width: 480px) {
            .container {
                width: 380px;
            }

            .slide_partner {
                font-size: 10px;
                height: 110px !important;
            }

            .slider__item {
                height: 170px;
            }

            .slider__text {
                line-height: 1.5;
                font-size: 11px;
            }

            .slider__title {
                margin: 10px 0;
                line-height: 1;
                font-size: 18px;
            }

            .slider__btn a {
                font-size: 70%;
            }

            .slider__btn {
                border: 1px solid white;
                border-radius: 5px;
                width: 100px;
                margin-top: 10px;
                height: 23px;
            }
            .footer__sitemap{
                column-gap: 5px;
            }

            .footer__short-menu a, .footer__sitemap a {
                font-size: 0.56rem;
            }

            .copyright__text {
                font-size: 0.6rem;
            }

            .footer__contacts {
                font-size: 0.6rem;
            }


        }.about__title, .about__title-sphera,
         .news__title, .capabilities__title,
         .clients__title, .partners__title,
         .testimonials__title, .services__title {
             font-size: 16px;
             text-transform: uppercase;
             line-height: 1.5;
         }

        .about__text, .services__text {
            font-size: 11px;
            margin: 0;
        }

        .about__link {
            line-height: 1;
            font-size: 11px;
            font-weight: 600;
        }


        .clients__item img, .partners__item img {
            width: 105px;
            margin: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            box-shadow: 0px 7px 24.3px 2.7px rgba(11, 38, 124, 0.14);
        }


        .clients, .partners {
            padding-bottom: 0;
        }

        .slide_news a div:last-child {
            grid-column-start: 2;
        }

        .slide_news {
            width: 100%;
            color: #15337e;
            font-size: 8px;
            line-height: 1;
            display: grid !important;
            grid-template-columns: 1fr 6fr;
            background: white;
            height: 40px !important;
        }

        .slide_news div {
            justify-content: center;
        }

        .slide_activity .slide_news {
            justify-content: flex-start;
        }

        .slide_activity .slide_news:active {
            -webkit-filter: brightness(80%);
        }

        .header__content-logo img {
            width: 100px;
        }

    }
    @media screen and (max-width: 370px) {
        .container {
            width: 320px;
        }

        .project_slide {
            height: 170px !important;
            display: grid;
            border-radius: 0;
            grid-template-columns: 1fr 2fr;
            grid-template-rows: 1fr 1fr;
        }

        .project_slide img {
            width: 120px;
            height: 120px;
            margin-left: 10px;
            grid-row-start: 1;
            grid-row-end: 3;
        }

        .clients__item img, .partners__item img {
            width: 85px;
        }

        .about__item-text {
            font-size: 9px;
        }
    }
    @media screen and (min-width: 320px) {
        .textLocation {
            font-size: 13px
        }

        .location {
            display: flex;
            align-items: center;
            flex-direction: column;
        }
    }

}