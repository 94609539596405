table {
  overflow:visible;
  td {
    vertical-align: middle;
    max-width: 250px;
    min-width: 20px;
    span{
      display: -webkit-box;
      word-break: break-all;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }

  th{}
  background: white;
}

.title{
  overflow-x:hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.custom-table__action-btn:hover{
  -webkit-filter: brightness(70%);

}
.custom-table__action-btn {
  cursor: pointer;
  border: 1px solid #eee;
  background: none;
  margin-right: 5px;
  position: relative;
  svg {
    pointer-events: none;
    padding: 5px;
    width: 1.7em;
    height: 1.7em;
  }
}