.menu__choose {
    display: flex;
    justify-content: space-evenly;
    font-size: 22px;
    width: 100%;
}

.menu__choose .choosing_element {
    height: 100%;
    display: flex;
    font-family: Raleway;
    font-size: 80%;
    justify-content: center;
    align-items: center;
}

.active_menu_choose div {
    color: rgba(21, 51, 126, 0.5);
}

.menu__choose .choosing_element:hover {
    color: rgba(21, 51, 126, 0.5);
}

.menu__choose a {
    color: #15337e;
    font-weight: 600;
    text-align: center;
}

.services_main_text {
    font-weight: 600;
}

.about__main__title {
    font-family: SansSerifBold;
    text-transform: uppercase;
    color: #15337e;
    font-weight: 600;
    font-size: 30px;
    margin-bottom: 20px;
}

.services_main_title {
    font-family: SansSerifBold;
    text-transform: uppercase;
    color: black;
    font-weight: 600;
    font-size: 36px;
    margin-bottom: 20px;
}

.courses__item__btn:hover {
    background-color: #15337eed;
}

.courses__item__btn a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    font-weight: 600;
    color: #15337e;
}

.courses__item__btn a:hover {
    color: white;
}

.center_container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.courses__item__btn {
    border: 2px solid #15337eed;
    border-radius: 10px;
    width: 150px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.course__button {
    text-align: center;
    color: white;
    border-radius: 5px;
    width: 100%;
    height: 100%;
    background: #15337e;
}

.course__table__body tr td {
    margin: 5px;
}

.course__button:hover {
    cursor: pointer;
}

.course__table {
    padding-top: 20px;
    background: rgba(51, 51, 51, 0.1);
}

.services_main,
.services_menu,
.course__table {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.by_sectors_menu {
    background-color: rgba(51, 51, 51, 0.1);
    padding-top: 20px;
    padding-bottom: 40px;
    display: flex;
    width: 100%;
    justify-content: center;
}

.image_sectors_container,
.image_service_container {
    display: flex;
    justify-content: space-between;
    place-content: center;
    flex-wrap: wrap;
    gap: 20px;
}

.part_block_text {
    margin-bottom: 2rem;
}

.block__text {
    margin-top: 15px;
    text-align: justify;
    font-size: 22px;
    line-height: 1.2;
}

.image_sectors_container div,
.image_service_container div {
    position: relative;
}

.image_sectors_container div :hover,
.image_service_container div :hover {
    cursor: pointer;
}

.inner_text:hover {
    background-color: rgba(0, 0, 0, 0.2);
}

.image_service_container div img {
    width: 260px;
    height: 169px;
    -webkit-filter: brightness(50%);
}

.image_sectors_container div img {
    width: 365px;
    height: 200px;
    -webkit-filter: brightness(50%);
}

.image_sectors_container div .inner_image:before,
.image_service_container div .inner_image:before {
    /*content: '';*/
    /*width: 100%;*/
    /*height: 100%;*/
    /*position: absolute;*/
    /*top: 0; left: 0;*/
    /*background-color: rgba(0,0,0,0.4);*/
}

.title_sectors_container,
.course__table__title,
.title_service_container {
    font-size: 26px;
    margin-left: 30px;
    margin-bottom: 10px;
    color: black;
    font-weight: 600;
}

.image_sectors_container div .inner_text,
.image_service_container div .inner_text {
    position: absolute;
    text-align: center;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
}

.image_sectors_container div .inner_text span,
.image_service_container div .inner_text span {
    color: white;
    background-color: rgba(0, 0, 0, 0);
    font-size: 24px;
    font-weight: 700;
    text-shadow: black 0 0 2px;
    line-height: 1.5;
}

.services_menu {
    margin-top: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 20px;
    border-bottom: 1px solid #15337e;
    border-top: 1px solid #15337e;
}

.projects__item__btn:hover {
    background-color: white;
}

.projects__item__btn a {
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    font-weight: 600;
    font-size: 80%;
    color: white;
}

.projects__item__btn a:hover {
    color: #15337eed;
}

.projects__item__btn {
    border: 2px solid white;
    border-radius: 10px;
    width: 150px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px;
}

.breadcrumb__react {
    margin-top: 10px;
    margin-bottom: 30px;
}

.breadcrumb__react a {
    color: black;
}

.breadcrumb__react a:hover {
    color: gray;
}

.download_about {
    font-size: 26px;
    font-family: SansSerifBold;
    font-weight: 800;
}

.download_about + div {
    display: flex;
    margin-left: 20px;
    flex-direction: column;
}

.mobile__button {
    margin-top: 10px;
    background: #15337e;
    width: 100%;
    display: flex;
    justify-content: center;
    border-radius: 5px;
}

.mobile__button:active {
    -webkit-filter: brightness(70%);
}

.mobile__button a {
    color: white;
    width: 100%;
    text-align: center;
    font-weight: 600;
}

.education__title {
    display: flex;
    flex-direction: row;
}

.education__logo {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #15337e;
}

.education__logo div:first-child {
    font-size: 50px;
    width: 83px;
    overflow: hidden;
    line-height: 0.9;
    font-family: "Miratrix";

}

.education__logo div:last-child {
    width: 120px;
    font-family: Raleway, serif;
}

.education__text {
    line-height: 2;
    border-left: 4px solid #15337e;
    padding-left: 10px;
}

.careers-slide {
    height: 400px;
    background: rgba(21, 51, 126, 0.1);
    border-radius: 10px;
    display: flex;
    flex-direction: row;
}

.careers-box {
    width: 90px;
    height: 90px;
    background: rgb(21, 51, 126);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.careers-text {
    display: grid;
    grid-template-rows: 1fr 1fr 5fr 1fr 1fr;
    margin-top: 20px;
    margin-right: 20px;
    text-align: justify;
    width: 100%;
}

.careers-text span {
    display: flex;
    align-items: center;
    justify-content: center;
}

.careers-text div {
    display: flex;
    justify-content: space-between;
    margin: 0 0 10px 0;
}

.newscard_title {
    display: flex;
    flex-direction: row;
}
.newscard_title div:first-child {
    display: flex;
    color: #15337e;
    font-weight: 600;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
    width: 260px;
    font-size: 28px;
}

.newscard_title div:last-child {
    border-left: 4px solid #15337e;
    padding-left: 10px;
    color: black;
    font-weight: 600;
    font-size: 26px;
}

.newscard_text {
    margin: 20px 0;
    min-height: 300px;
    /*text-align: justify;*/
    word-break: break-all;
}

.newscard_text img {
    width: 250px;
    height: 250px;
    float:left;
    margin: 0 40px 10px 0;
}

.educationcard__text__about {
    text-align: justify;
    margin-right: 5px;
}

.educationcard__text__about h3 {
    font-weight: 900;
}

.educationcard__text__about h6 {
    color: #15337e;
    font-weight: 900;
}

.educationcard__sidebar {
    float: right;
    color: black;
    background: rgba(21, 51, 126, 0.1);
    padding: 10px;
    display: flex;
    flex-direction: column;
}

.educationcard__sidebar h5 {
    color: #15337e;
    font-weight: 600;
}

.educationcard__sidebar img {
    width: 250px;
    align-self: center;
}

.name-meneger {
    align-self: center;
}

.educationcard__sidebar p {
    margin-bottom: 0.5rem;
}

.educationcard__about {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.educationcard__sidebar__btn {
}

.educationcard__text__about li {
    list-style: initial;
}

.educationcard__teacher {
    display: flex;
    flex-direction: column;
}

.educationcard__teacher__title {
    margin-top: 1.2rem;
    font-weight: 600;
    text-transform: uppercase;
}

.educationcard__teacher__slide {
    display: flex;
    flex-direction: row;
    margin-top: 1rem;
    text-align: justify;
}

.educationcard__teacher__slide img {
    width: 200px;
    height: 250px;
}

.educationcard__teacher__slide div {
    margin-left: 20px;
}

.educationcard__teacher__slide h5 {
    color: #15337e;
    font-weight: 900;
}

.feedback__slide {
    height: 30%;
    background: rgba(21, 51, 126, 0.2);
    border-radius: 10px;
    display: flex;
    flex-direction: row;
}

.feedback__box {
    margin: 20px;
}

.feedback__box div {
    width: 90px;
    height: 90px;
    background: rgb(21, 51, 126);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.feedback__text {
    display: grid;
    grid-template-rows: 1fr 1fr 5fr 1fr 1fr;
    margin-top: 20px;
    margin-right: 20px;
}

.slide_button {
    color: white;
    width: 100%;
    height: 100%;

}

.slide_button div {
    width: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    height: 100%;
    border: 1px solid #15337e;
    border-Radius: 10px;
    background: #15337e;
    justify-Content: center;
}
.slide_button:hover{
    color: #15337e;
}
.slide_button div:hover{
    background: white;
}
.feedback__text q {
    text-align: justify;
}

.feedback__text div {
    color: #15337e;
    font-weight: 900;
}
.main{
    min-Height: calc(100vh - 200px);
}
.topmenu_createmenu{
    display: flex;
    flex-direction: column;

}

@media screen and (max-width: 1080px) {
    
}
@media screen and (max-width: 768px) {
    .download_about {
        font-size: 18px;
        font-family: SansSerifBold;
    }

    .feedback__slide {
        height: 30%;
        border-radius: 10px;
        font-size: 10px;
        line-height: 1.2;
        text-align: justify;
        display: flex;
        flex-direction: row;
    }

    .feedback__box {
        margin: 10px 5px;
    }

    .feedback__box div {
        width: 50px;
        height: 50px;
        background: rgb(21, 51, 126);
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .feedback__text {
        display: grid;
        grid-template-rows: 1fr 1fr 5fr 1fr 1fr;
        margin-top: 10px;
        margin-right: 10px;
    }

    .feedback__text q {
        text-align: justify;
    }

    .feedback__text div {
        color: #15337e;
        font-weight: 900;
    }

    .educationcard__teacher__title {
        font-size: 18px;
    }

    .educationcard__teacher__slide {
        display: flex;
        flex-direction: row;
        margin-top: 1rem;
        text-align: justify;
        font-size: 11px;
        line-height: 1.2;
    }

    .educationcard__teacher__slide img {
        width: 80px;
        height: 100px;
    }

    .educationcard__teacher__slide div {
        margin-left: 10px;
    }

    .educationcard__teacher__slide h5 {
        color: #15337e;
        font-weight: 900;
        font-size: 14px;
    }

    .educationcard__text__about {
        font-size: 11px;
        line-height: 1.2;
    }

    .educationcard__sidebar {
        width: 100%;
        padding: 10px;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        flex-wrap: wrap;
        font-size: 8px;
        line-height: 1.2;
    }

    .educationcard__about {
        flex-direction: column-reverse;

    }

    .educationcard__sidebar ul {
        padding-left: 10px;
    }
    .educationcard__sidebar h5 {
        text-align: center;
        font-size: 8px;
        margin-top: 5px;
        margin-bottom: 2px;
    }

    .educationcard__text__about h6 {
        font-size: 11px;
    }

    .educationcard__text__about h3 {
        font-size: 16px;
    }
    .educationcard__about .about__item__btn,.licence_block, .educationcard__sidebar img{
        display: none;
    }
    .educationcard__sidebar img {
        display: none;
    }

    .newscard_text {
        /*font-size: 11px;*/
        /*line-height: 1.2;*/
    }

    .newscard_text img {
        width: 150px;
        height: 150px;
    }

    .download_about + div {
        flex-direction: row;
        margin-left: 0;
        justify-content: space-evenly;
    }

    .breadcrumb__react {
        font-size: 11px;
    }

    h4 {
        font-size: 11px;
    }

    .projects__item__btn {
        width: 150px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: auto;
    }
    .projects__item__btn a{
        text-align: center;
    }
    .services_main_title {
        font-weight: 600;
        font-size: 20px;
        margin-bottom: 10px;
    }

    .projects__item__btn:hover {
        background-color: #15337eed;
    }

    .projects__item__btn a:hover {
        color: white;
    }

    .projects__item__btn a:active {
        color: #15337eed;
    }

    .projects__item__btn:active {
        background-color: white;
    }



    .part_block_text {
        margin-bottom: 1rem;
    }

    .block__text {
        font-size: 18px;
    }

    .block__text p {
        font-size: 14px;
    }

    .about__main__title {
        font-size: 20px;
        margin-bottom: 10px;
    }

    .education__logo div:first-child {
        font-size: 30px;
        width: 50px;
        overflow: hidden;
        line-height: 0.9;
        font-family: Miratrix, serif;
    }

    .education__logo div:last-child {
        font-size: 11px;
        width: 70px;
        line-height: 1;
        font-family: Raleway, serif;
    }

    .education__text {
        line-height: 2;
        font-size: 9px;
        border-left: 4px solid #15337e;
        padding-left: 10px;
    }

    .careers-slide {
        height: 250px;
        background: rgba(21, 51, 126, 0.1);
        border-radius: 10px;
        display: flex;
        flex-direction: row;
    }

    .careers-box {
        font-size: 9px;
        width: 45px;
        height: 45px;
        background: rgb(21, 51, 126);
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .careers-text {
        display: grid;
        grid-template-rows: 1fr 1fr 5fr 1fr 1fr;
        margin-top: 10px;
        font-size: 8px;
        line-height: 1.2;
        margin-right: 10px;
        text-align: justify;
    }

    .careers-text span {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .careers-text div {
        display: flex;
        justify-content: space-between;
    }

    .newscard_title div:first-child {
        display: flex;
        color: #15337e;
        font-weight: 600;
        align-items: center;
        justify-content: center;
        margin-right: 5px;
        width: 140px;
        font-size: 16px;
    }

    .newscard_title div:last-child {
        border-left: 4px solid #15337e;
        padding-left: 10px;
        color: black;
        font-weight: 600;
        font-size: 14px;
    }

}
@media screen and (max-width: 768px) {
    .header__content {
        overflow: hidden;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: normal;
        background-color: rgb(240, 240, 240, 0.9);
        margin: 0 auto;
        padding-left: 20px;
        z-index: 99;
    }

    .header__inner {
        display: flex;
        justify-content: space-around;
        align-items: center;
    }
    .topmenu {
        display: flex;
        justify-content: space-between;
    }
    a:hover {
        opacity:0.5;
    }
    ul {
        padding: 0;
        list-style-type: none;
    }
    nav {
        height: 65px;
    }
    .mySwiper{
        z-index: 0;
    }

    #menuToggle {
        display: flex;
        flex-direction: column;
        position: relative;
        float:right;
        top: 30px;
        right: 35px;
        z-index: 1;
        -webkit-user-select: none;
        user-select: none;
    }

    #menuToggle input
    {
        display: flex;
        width: 40px;
        height: 32px;
        position: absolute;
        cursor: pointer;
        opacity: 0;
        z-index: 2;
    }

    #menuToggle span
    {
        display: flex;
        width: 29px;
        height: 2px;
        margin-bottom: 5px;
        position: relative;
        background: black;
        border-radius: 3px;
        z-index: 1;
        transform-origin: 5px 0px;
        transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
        background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
        opacity 0.55s ease;
    }

    #menuToggle span:first-child
    {
        transform-origin: 0% 0%;
    }

    #menuToggle span:nth-last-child(2)
    {
        transform-origin: 0% 100%;
    }

    #menuToggle input:checked ~ span
    {
        opacity: 1;
        transform: rotate(45deg) translate(-3px, -1px);
        background: #36383F;
    }
    #menuToggle input:checked ~ span:nth-last-child(3)
    {
        opacity: 0;
        transform: rotate(0deg) scale(0.2, 0.2);
    }

    #menuToggle input:checked ~ span:nth-last-child(2)
    {
        transform: rotate(-45deg) translate(0, -1px);
    }

    #menu
    {
        position: fixed;
        width: 300px;
        overflow-x: hidden;
        height: 100vh;
        box-shadow: 0 0 10px #85888C;
        margin: -50px 0 0 -230px;
        padding: 20px;
        padding-top: 60px;
        background-color: #F5F6FA;
        -webkit-font-smoothing: antialiased;
        transform-origin: 0% 0%;
        transform: translate(100%, 0);
        transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
    }
    .menu__choose {
        line-height: 1;
        font-size: 0.8rem;
        margin: 0 0 0 0;
    }
    #menu li
    {
        width: 100%;
        padding: 10px 0;
        transition-delay: 2s;
        text-align: left;

    }
    #menu selector{

    }
    #menu a{
        color: #15337e;
    }
    .main{
        padding-top: 75px;
    }
    #menuToggle input:checked ~ ul
    {
        transform: none;
    }
}
@media screen and (max-width: 480px) {
    .block__text p {
        font-size: 12px;
    }
    .education__text {
        line-height: 2;
        font-size: 7px;
        border-left: 4px solid #15337e;
        padding-left: 10px;
    }

    .menu__choose {
        line-height: 1;
        font-size: 0.5rem;
        margin: 0 0 0 0;
    }
}
